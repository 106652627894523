import React from 'react';
import classnames from 'classnames';
import { element, bool, string, oneOf, func } from 'prop-types';
import styles from './button.module.scss';


const classByType = {
  primary: styles.primary,
  secondary: styles.secondary,
};

const classBySize = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
};

const Button = (props) => {
  const { disabled, icon, iconSide, label, onClick, size, type, className } = props;

  const buttonClass = classnames(styles.button,
    (classByType[type] || classByType.primary),
    (classBySize[size] || classBySize.medium),
    className);

  const contentWithIcon = React.useMemo(() => ({
    left: (
      <>
        <div className={styles.icon}>{icon}</div>
        <span className={styles.label}>{label}</span>
      </>
    ),
    right: (
      <>
        <span className={styles.label}>{label}</span>
        <div className={styles.icon}>{icon}</div>
      </>
    ),
  }), []);

  const buttonContent = React.useMemo(() => {
    if (icon) {
      return contentWithIcon[iconSide];
    }

    return (
      <span className={styles.label}>
        {label}
      </span>
    );
  }, []);

  const onClickHandler = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <button
      className={buttonClass}

      onClick={onClickHandler}
      disabled={disabled}
    >
      <div className={styles.content}>
        {buttonContent}
      </div>
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  onClick: () => { },
  size: 'large',
  type: 'primary',
};

Button.propTypes = {
  disabled: bool,
  icon: element,
  iconSide: oneOf(['left', 'right']),
  label: string.isRequired,
  onClick: func,
  size: oneOf(['small', 'large']),
  type: oneOf(['primary', 'secondary']),
};

export default Button; 