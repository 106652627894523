const baseUrl = "https://wabudweiser-prd.azurewebsites.net/budcovers"
// const baseUrl = "http://localhost:8080/budcovers";

export const uploadParticipant = async data => {
  const response = await fetch(`${baseUrl}/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
  const parsedResp = await response
  return parsedResp
}

export const search = async (searchInput, limit, approved, email, filter) => {
console.log("filteeer", filter)

  const response = await fetch(
    `${baseUrl}/search?search=${searchInput}&approved=${approved}&limit=${limit}&email=${email}&filter=${filter}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
  const parsedResp = await response.json()
  console.log(parsedResp)
  return parsedResp
}

export const uploadVoter = async data => {
  const response = await fetch(`${baseUrl}/voters`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
  const parsedResp = await response
  return parsedResp
}

export const getUserById = async userId => {
  const response = await fetch(`${baseUrl}/user?userId=${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
  const parsedResp = await response.json()
  return parsedResp[0]
}

export const voteUser = async userId => {
  const response = await fetch(`${baseUrl}/updatevote?userId=${userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  })

  console.log(response)
  return response
}
