import React from 'react';
import { bool, number } from 'prop-types';
import SvgIcon from '../../SvgIcon';
import { getUserData } from '../../../../utils';
import styles from './likes.module.scss';

const Likes = ({ coverId, inCover, likes }) => {
  const cover = inCover ? styles.cover : '';
  const { votes = [] } = getUserData();
  const iLike = votes.some(id => id == coverId);
  const svg = iLike ? 'like-vote' : 'like-no-vote';

  return (
    <div className={`${styles.likes} ${cover}`}>
      <SvgIcon name={svg} size='20px' />
      <p>{likes}</p>
    </div>
  );
};

Likes.propTypes = {
  coverId: number,
  inCover: bool,
  likes: number
};

Likes.defaultProps = {
  coverId: 0,
  inCover: false,
  likes: 0
};

export default Likes;