import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import SvgIcon from "../../../SvgIcon"
import styles from "./footer.module.scss"
import { IsMobileDevice } from "../../../../../utils"

const Footer = props => {
  const { type, onlyText } = props
  const [isMobile, setIsMobile] = useState()

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  const handleTyC = () => {
    window.location.replace("/budstage/tyc")
  }

  if (onlyText) {
    return (
      <footer className={styles.footer}>
        <p className={styles.bottom}>
          BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO
          COMPARTA EL CONTENIDO CON MENORES.
          <br />® Budweiser - Buenos Aires - Argentina. Todos los derechos
          reservados.
        </p>
      </footer>
    )
  }

  if (type === "secondary") {
    return (
      !isMobile && (
        <footer className={styles.secondaryFooter}>
          <a onClick={handleTyC}>VER BASES Y CONDICIONES</a>
          <SvgIcon
            name="we-brew-icon"
            size="4.290vw"
            className={styles.weBrewIcon}
          />
        </footer>
      )
    )
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.top}>
        <ul>
          <li>
            <a href="mailto:Budweiser.arg@gmail.com">CONTACTO</a>
          </li>
          <li>
            <a onClick={handleTyC}>TÉRMINOS Y CONDICIONES</a>
          </li>
          <li>
            <a href="/PoliticadePrivacidad.pdf" target="blank">
              POLÍTICA DE PRIVACIDAD
            </a>
          </li>
          <li>
            <a href="#">CONSUMO RESPONSABLE</a>
          </li>
          <li>
            <a
              href="https://www.cerveceriaymalteriaquilmes.com/"
              alt="informacion oficial"
              target="_blank"
            >
              INFORMACIÓN OFICIAL DE LA COMPAÑÍA
            </a>
          </li>
        </ul>
        <div className={styles.redLine} />
        <div className={styles.social}>
          <a
            href="https://www.instagram.com/budargentina/"
            alt="Budweiser Instagram"
            target="_blank"
          >
            <SvgIcon
              name="instagram-icon"
              size={isMobile ? "4.154vw" : "1.119vw"}
            />
          </a>
          <a
            href="https://www.facebook.com/BudweiserArgentina/"
            alt="Budweiser Facebook"
            target="_blank"
          >
            <SvgIcon name="fb-icon" size={isMobile ? "2.123vw" : "0.569vw"} />
          </a>
          <a
            href="https://twitter.com/budargentina"
            alt="Budweiser Twitter"
            target="_blank"
          >
            <SvgIcon
              name="twitter-icon"
              size={isMobile ? "4.321vw" : "1.170vw"}
            />
          </a>
          <a
            href="https://www.youtube.com/user/budargentina"
            alt="Budweiser Youtube"
            target="_blank"
          >
            <SvgIcon
              name="youtube-icon"
              size={isMobile ? "3.705vw" : "1.003vw"}
            />
          </a>
        </div>
      </div>
      {isMobile ? (
        <p className={styles.bottom}>
          BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. <br />{" "}
          NO COMPARTA EL CONTENIDO CON MENORES. <br />® Budweiser - Buenos Aires
          - Argentina. Todos los derechos reservados.
        </p>
      ) : (
        <p className={styles.bottom}>
          BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO
          COMPARTA EL CONTENIDO CON MENORES. <br />® Budweiser - Buenos Aires -
          Argentina. Todos los derechos reservados.
        </p>
      )}
    </footer>
  )
}

export default Footer
